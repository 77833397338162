/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductViewRefetchQueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type ProductViewRefetchQueryResponse = {|
  +viewer: ?{|
    +checkSavedUrlRewrites: ?any
  |}
|};
export type ProductViewRefetchQuery = {|
  variables: ProductViewRefetchQueryVariables,
  response: ProductViewRefetchQueryResponse,
|};
*/


/*
query ProductViewRefetchQuery(
  $ids: [ID]
) {
  viewer {
    checkSavedUrlRewrites: savedUrlRewrites(ids: $ids)
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": "checkSavedUrlRewrites",
  "args": [
    {
      "kind": "Variable",
      "name": "ids",
      "variableName": "ids"
    }
  ],
  "kind": "ScalarField",
  "name": "savedUrlRewrites",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductViewRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductViewRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06be72d641c1a2166f993941cdbb6642",
    "id": null,
    "metadata": {},
    "name": "ProductViewRefetchQuery",
    "operationKind": "query",
    "text": "query ProductViewRefetchQuery(\n  $ids: [ID]\n) {\n  viewer {\n    checkSavedUrlRewrites: savedUrlRewrites(ids: $ids)\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c292ad20d0e7028548e2bfc4bb995ecc';

module.exports = node;
