/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UrlRewriteReminder_viewer$ref: FragmentReference;
declare export opaque type UrlRewriteReminder_viewer$fragmentType: UrlRewriteReminder_viewer$ref;
export type UrlRewriteReminder_viewer = {|
  +categories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +status: ?boolean,
        +urlSlug: ?string,
        +includeInMenu: ?boolean,
      |}
    |}>
  |},
  +subcategories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +status: ?boolean,
        +urlSlug: ?string,
      |}
    |}>
  |},
  +savedUrlRewrites: ?any,
  +$refType: UrlRewriteReminder_viewer$ref,
|};
export type UrlRewriteReminder_viewer$data = UrlRewriteReminder_viewer;
export type UrlRewriteReminder_viewer$key = {
  +$data?: UrlRewriteReminder_viewer$data,
  +$fragmentRefs: UrlRewriteReminder_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "asc",
      "field": "name"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UrlRewriteReminder_viewer",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "CategoryConnection",
      "kind": "LinkedField",
      "name": "categories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Category",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "includeInMenu",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "categories(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "SubcategoryConnection",
      "kind": "LinkedField",
      "name": "subcategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubcategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Subcategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "subcategories(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "kind": "ScalarField",
      "name": "savedUrlRewrites",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '556c1b84a92a52bb78f594ca991e8108';

module.exports = node;
