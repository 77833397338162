import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { clone, get, startCase } from 'lodash';
import { Button, Select, Switch } from 'antd';

import { getNodeIds } from '~/helper';
import { isDataEntry } from '~/components/form';
import { BulkUpdateProductsMutation} from './mutations';
import { VISIBILITIES } from '../ProductForm';

const { Option } = Select;

class BulkUpdateProduct extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    gridApi: PropTypes.shape({
      getSelectedRows: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const dataEntry = isDataEntry(props.viewer);

    this.attributeList = [
      {
        code: 'exclude_global_free_shipping',
        label: 'Exclude Global Free Shipping',
        node: <Switch onChange={(value) => this.setState({ value })} />
      },
      {
        code: 'online_only',
        label: 'Disable Click and Collect',
        node: <Switch onChange={(value) => this.setState({ value })} />
      },
      {
        code: 'promo_card',
        node: <Switch onChange={(value) => this.setState({ value })} />
      },
      {
        code: 'in_store_only',
        node: <Switch onChange={(value) => this.setState({ value })} />
      },
      {
        code: 'status',
        node: (
          <Select style={{width: '150px'}} disabled={dataEntry} placeholder="Status" onChange={(value) => this.setState({ value })} >
            <Option value="enabled">Enabled</Option>
            <Option value="disabled">Disabled</Option>
            <Option value="end-of-life">End Of Life</Option>
          </Select>
        )
      },
      {
        code: 'visibility',
        node: (
          <Select style={{width: '150px'}} placeholder="Visibility" onChange={(value) => this.setState({ value })}>
            {
              VISIBILITIES.map(v => (
                <Option key={v.id} value={v.id}>{v.label}</Option>
              ))
            }
          </Select>
        )
      },
    ];

    this.state = {
      attributeCode: null,
      value: null,
    };
  }

  setStartDate = (val) => {
    this.setSchedule("startDate", val);
  }

  setEndDate = (val) => {
    this.setSchedule("endDate", val);
  }

  setMultiplier = (val) => {
    this.setSchedule("multiplier", val);
  }

  setSchedule = (code, val) => {
    let value = clone(this.state.value);

    if (typeof value !== 'object' || value === null) {
      value = {};
    }

    value[code] = val;

    this.setState({value});
  }

  handleSubmit = () => {
    const { attributeCode, value } = this.state;
    const productIds = getNodeIds(this.props.gridApi.getSelectedRows());

    if (productIds.length) {
      BulkUpdateProductsMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { ids: productIds, [attributeCode]: value } },
        viewer: this.props.viewer,
      });
    }
  }

  renderAttributeInput = (code) => {
    const attr = this.attributeList.find(a => a.code === code);

    if (attr) {
      return React.cloneElement(attr.node);
    }
    return null;
  }

  render() {
    return (
      <div style={{display: 'inline-block'}}>
        <Select
          key="attribute"
          placeholder="Attribute"
          style={{ width: '200px' }}
          onChange={(attributeCode) => {
            this.setState({ attributeCode });
          }}
        >
          {this.attributeList.map(attr => {
            const label = get(attr, 'label', startCase(attr.code));

            return (
              <Option key={attr.code}>
                {label}
              </Option>
            )
          })}
        </Select>

        {this.renderAttributeInput(get(this.state, 'attributeCode'))}

        <Button
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default createFragmentContainer(BulkUpdateProduct, {
  viewer: graphql`
    fragment BulkUpdateProduct_viewer on Admin {
      id
      roles(first: 99){
        edges {
          node {
            name
          }
        }
      }
    }
  `,
});
