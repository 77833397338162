import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import moment from 'moment-timezone';
import { Button, Checkbox, DatePicker } from 'antd';
import { fromGlobalId, genCsv } from '~/helper';
import { ExportProductsMutation } from './mutations';

const { Group } = Checkbox;

class ExportProducts extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    gridApi: PropTypes.shape({
      getFilterModel: PropTypes.func.isRequired,
      getSelectedRows: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.defaultSelect = ['name', 'type', 'model', 'sku', 'second_model', 'price', 'status'];

    this.options = [
      { label: 'Id', value: 'id'},
      { label: 'Website Ref', value: 'website_reference'},
      { label: 'Name', value: 'name', disabled: true },
      { label: 'Type', value: 'type', disabled: true },
      { label: 'Model', value: 'model', disabled: true },
      { label: 'SKU', value: 'sku', disabled: true },
      { label: 'Second Model', value: 'second_model', disabled: true },
      { label: 'Price', value: 'price', disabled: true },
      { label: 'Cost Price', value: 'cost_price' },
      { label: 'Status', value: 'status', disabled: true },
      { label: 'Bundle Content', value: 'bundles' },
      { label: 'Partial Name', value: 'partial_name' },
      { label: 'Url', value: 'full_url' },
      { label: 'Image', value: 'main_image' },
      { label: 'Aden Code', value: 'aden_code' },
      { label: 'Slim Aden Code', value: 'slim_aden_code' },
      { label: 'Odoo Code', value: 'odoo_code' },
      { label: 'Attributes', value: 'attributes' },
      { label: 'Price Drop', value: 'price_drop' },
      { label: 'BarCode', value: 'barcode' },
      { label: 'Brand', value: 'brand' },
      { label: 'Categories', value: 'categories' },
      { label: 'Subcategories', value: 'subcategories' },
      { label: 'Weight', value: 'weight' },
      { label: 'Width', value: 'width' },
      { label: 'Length', value: 'length' },
      { label: 'Height', value: 'height' },
      { label: 'Stock Available', value: 'stock_available' },
      { label: 'Stock On Hand', value: 'stock_on_hand' },
      { label: 'Non Stock', value: 'non_stock' },
      { label: 'Private Label', value: 'private_label' },
      { label: 'Created At', value: 'inserted_at' },
    ];

    this.state = {
      selectedOption: this.defaultSelect,
      priceDropDate: null,
    };
  }

  onChange = (selectedOption) => {
    this.setState({selectedOption})
  }

  onDateChange = (v) => {
    this.setState({
      priceDropDate: v
    });
  }

  handleSubmit = () => {
    const productFilters = this.props.gridApi.getFilterModel();

    const ids = this.props.gridApi.getSelectedRows().map(( {node} )=> fromGlobalId(node.id).id).join(',');

    let selections = [];

    if (ids.length > 0 ) {
      selections = [{
        field: 'id',
        filter: ids,
        filterType: 'number',
        type: 'in'
      }];
    }

    const filters = Object.keys(productFilters).map(i => {
      const f = productFilters[i];

      return {
        field: i,
        filter: String(f.filter),
        filterType: f.filterType,
        type: f.type
      };
    }).concat(selections);

    const options = {
      priceDropDate: this.state.priceDropDate,
    };

    const values = {
      filters,
      query: this.state.selectedOption,
      options,
    }

    const prepareHeaders = (selectedOption) => {
      const headers = [...selectedOption];
      const index = headers.indexOf("price_drop");
      if (index >= 0) {
        headers.splice(index+1, 0, "regular_price", "price_drop_start", "price_drop_end");
      }
      return headers;
    }

    const getFilename = (timeNow) => {
      const { priceDropDate } = this.state;
      const { COUNTRY } = process.env;

      if (this.isPriceDropSelected() && priceDropDate) {
        return `${COUNTRY}-Export-PriceDrops-${priceDropDate.format('YYYY-MM-DD-HH:mm:ss')}`;
      }
      return `${COUNTRY}-Export-Products-${timeNow}`;
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        const { result } = resp.exportProducts;

        if (result.length > 0) {
          const csvHeaders = prepareHeaders(this.state.selectedOption);

          genCsv(
            result,
            csvHeaders,
            getFilename,
          );
        }

        // reset pricedrop to prevent moment locale crash
        this.setState({
          priceDropDate: null
        })
      }
    }

    ExportProductsMutation.commit(mutation);
  }

  isPriceDropSelected = () => this.state.selectedOption.includes("price_drop")

  render() {
    return (
      <div>
        <div>
          <Group
            style={{width: '50%'}}
            options={this.options}
            defaultValue={this.defaultSelect}
            onChange={this.onChange}
          />
        </div>
        {this.isPriceDropSelected() && (
          <div>
            Price Drop Date:
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              value={this.state.priceDropDate}
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              onChange={this.onDateChange}
            />
            <br />
            <span style={{color: "grey"}}>
              Leave it Empty will show today&apos;s price
            </span>
          </div>
        )}
        <div>
          <Button
            style={{marginLeft: '10px'}}
            onClick={this.handleSubmit}
          >
            Download
          </Button>
        </div>
      </div>
    )
  }
}

export default createFragmentContainer(ExportProducts, {
  viewer: graphql`
    fragment ExportProducts_viewer on Admin {
      id
    }
  `,
});
