import React, { useEffect, useState } from 'react';
import {
    createRefetchContainer,
    graphql,
  } from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Alert, Affix, message, Modal, Select, Tag } from 'antd';

const { Option } = Select;

const Suggestion = (props) => {
  if (props.suggestion.length === 0) {
    return null;
  }

  const { suggestion, onChange, chosenId } = props;

  return (
    <small style={{ marginTop: '5px' }}>
      Suggested: {suggestion.map(s => (
        <Tag.CheckableTag
          key={s.id}
          checked={s.id === chosenId}
          onChange={() => {onChange(s)}}
        >
          {s.name}
        </Tag.CheckableTag>
      ))}
    </small>
  );
}

Suggestion.propTypes = {
  suggestion: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  chosenId: PropTypes.string,
};

Suggestion.defaultProps = {
  chosenId: undefined,
};

export const RemoveUrlReminder = (props) => {
  const { open, activeUrls, onCancel } = props;

  if (open && activeUrls.length > 0) {
    return (
      <Affix offsetTop={100}>
        <Alert
          message="Action Required"
          description={
            <>
              <p>
                There is a redirect URL on this product. The redirect needs to be removed before you can enable it.
              </p>
              <ul>
                {activeUrls.map(({ id }) => (
                  <li key={id}>
                    <a
                      href={`/url/rewrite/${id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      /url/rewrite/{id}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          }
          type="error"
          showIcon
          closable
          onClose={onCancel}
        />
      </Affix>
    )
  }
    return null;
  ;
}

RemoveUrlReminder.propTypes = {
  open: PropTypes.bool.isRequired,
  activeUrls: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
};

const UrlRewriteReminder = (props) => {
  const { viewer, product, relay, setState, state, supersededBy } = props;

  const { urlCategory, urlSubcategory, productStatus } = state;
  const subcategories = get(viewer, 'subcategories.edges', []);
  const categories = get(viewer, 'categories.edges', []);
  const savedUrlRewrites = get(viewer, 'savedUrlRewrites', []);
  const categorySuggestion = categories.filter(({node: {id, includeInMenu}}) => product.categoryIds?.includes(id) && includeInMenu).map(({ node }) => node).filter(cat => cat.name !== 'Shop By Brand');
  const subcategorySuggestion = subcategories.filter(({node: {id}}) => product.subcategoryIds?.includes(id)).map(({ node }) => node);
  const hasSupersededProducts = supersededBy?.length > 0;

  const [open, setOpen] = useState(undefined);
  const [catId, setCatId] = useState(undefined);
  const [subcatId, setSubcatId] = useState(undefined);

  useEffect(() => {
    if (!urlCategory) {
      setCatId(undefined);
      setSubcatId(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (product?.id) {
      relay.refetch({ ids: [product.id] }, null, () => {
        if (open === undefined) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      });
    }
  }, [productStatus]);

  const groupSavedUrls = () => {
    const disabledUrls = savedUrlRewrites.filter(({ status }) => !status);
    const activeUrls = savedUrlRewrites.filter(({ status }) => status);
    const noSavedUrl = savedUrlRewrites.length === 0;
    const preUrlDisabled = activeUrls.length === 0 && disabledUrls.length > 0;

    return [disabledUrls, activeUrls, noSavedUrl, preUrlDisabled];
  };

  const [disabledUrls, activeUrls, noSavedUrl, preUrlDisabled] = groupSavedUrls();

  const redirectUrl = () => (
    `${urlCategory ? `/category/${urlCategory}` : ''}${urlSubcategory ? `/${urlSubcategory}` : ''}`
  );

  return (
    <div>
      <Modal
        title="Manage Categories"
        visible={open && productStatus !== 'enabled' && noSavedUrl && !hasSupersededProducts}
        closable={false}
        onOk={() => {
          if (urlCategory) {
            setOpen(false);
          } else {
            message.error('Category must be chosen for redirect url');
          }
        }}
        onCancel={() => {
          setState((s) => ({...s, urlCategory: null, urlSubcategory: null}));
          setOpen(false);
        }}
      >
        <p>Redirect this product to a page IF it will not be enabled ever again.</p>
        <p>/product/{product.urlSlug}</p>
        <p>will be redirected to:</p>
        <span style={{ color: 'red', fontWeight: 'bold' }}>
          {redirectUrl()}
        </span>
        <Select
          placeholder="Category"
          optionFilterProp="children"
          optionLabelProp="children"
          allowClear
          onClear={() => {setState((s) => ({...s, urlCategory: null})); setCatId(undefined)}}
          labelInValue
          value={catId}
          showSearch
          onSelect={({key, value}) => {setState((s) => ({...s, urlCategory: key})); setCatId(value)}}
          style={{width: '100%'}}
        >
          {categories.map(({ node: o }) => (
            <Option key={o.urlSlug} value={o.id}>{o.name}</Option>
          ))}
        </Select>
        <Suggestion
          suggestion={categorySuggestion}
          onChange={(cat) => {
            if (cat.id === catId) {
              setState((s) => ({...s, urlCategory: null}));
              setCatId(undefined);
            } else {
              setState((s) => ({...s, urlCategory: cat.urlSlug}));
              setCatId(cat.id);
            }
          }}
          chosenId={catId}
        />

        <div style={{ marginTop: '5px' }}>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center'}}>
            <Select
              placeholder="Subcategories"
              optionFilterProp="children"
              optionLabelProp="children"
              allowClear
              onClear={() => {setState((s) => ({...s, urlSubcategory: null})); setSubcatId(undefined)}}
              labelInValue
              showSearch
              value={subcatId}
              onSelect={({key, value}) => {setState((s) => ({...s, urlSubcategory: key})); setSubcatId(value)}}
              style={{ flex: 1 }}
            >
              {subcategories.map(({ node: o }) => (
                <Option key={o.urlSlug} value={o.id}>{o.name}</Option>
            ))}
            </Select>
          </div>

          <Suggestion
            suggestion={subcategorySuggestion}
            onChange={(subcat) => {
              if (subcat.id === subcatId) {
                setState((s) => ({...s, urlSubcategory: null}));
                setSubcatId(undefined);
              } else {
                setState((s) => ({...s, urlSubcategory: subcat.urlSlug}));
                setSubcatId(subcat.id);
              }
            }}
            chosenId={subcatId}
          />
        </div>
      </Modal>

      <Modal
        visible={open && productStatus !== 'enabled' && preUrlDisabled && !hasSupersededProducts}
        closable
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <p>Consider enabling the following URL redirects:</p>
        <ul>
          {disabledUrls.map(({ id }) => (
            <li key={id}>
              <a
                href={`/url/rewrite/${id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                /url/rewrite/{id}
              </a>
            </li>
          ))}
        </ul>
      </Modal>

      {productStatus === 'enabled' && <RemoveUrlReminder open={open} activeUrls={activeUrls} onCancel={() => {setOpen(false)}} />}
    </div>
  );
};

UrlRewriteReminder.propTypes = {
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
    refetch: PropTypes.func.isRequired,
    }).isRequired,
  viewer: PropTypes.shape({
    categories: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    subcategories: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    savedUrlRewrites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  product: PropTypes.shape({
    urlSlug: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    categoryIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    subcategoryIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  supersededBy: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.shape({
    urlCategory: PropTypes.string,
    urlSubcategory: PropTypes.string,
    productStatus: PropTypes.string.isRequired,
  }).isRequired,
};

export default createRefetchContainer(UrlRewriteReminder, {
    viewer: graphql`
      fragment UrlRewriteReminder_viewer on Admin @argumentDefinitions(
        ids: {type: "[ID]", defaultValue: []}
      ) {
        categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
              id
              name
              status
              urlSlug
              includeInMenu
            }
        }
        }
        subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
                id
                name
                status
                urlSlug
            }
          }
        }
        savedUrlRewrites(ids: $ids)
      }
    `
  },
    graphql`
    query UrlRewriteReminderRefetchQuery($ids: [ID]) {
      viewer {
        savedUrlRewrites(ids: $ids)
      }
    }
  `
  );
