import React from 'react';
import PropTypes from 'prop-types';
import { get, uniq } from 'lodash';
import { Button, Divider, Form, InputNumber, Select } from 'antd';
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { fromGlobalId } from '~/helper';
import { formItemLayout, SelectProduct } from '~/components/form';
import { VISIBILITIES } from './ProductForm';

const { Item: FormItem } = Form;
const { Option } = Select;

const productOptions = (product) => ({
  status: get(product, 'status'),
  key: get(product, 'id', ''),
  label: (
    <div product={product} title={get(product, 'name')}>
      <img width="20" height="20" src={get(product, 'mainImage.url')} alt="" />
      {get(product, 'name')}
    </div>
  ),
});

export default class ConfigProduct extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    product: PropTypes.shape({
      configurables: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
      getFieldValue: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const attributes = get(this.props.viewer, 'attributes.edges', []);

    this.state = {
      configAttrsOptions: this.getConfigAttr(attributes),
      validateList: [],
    };
  }

  onProductChange = (product, index) => {
    const list = this.state.validateList;
    const foundIndex = list.findIndex(x => x.id === index);

    const value = {
      id: index,
      ...this.validateProduct(product)
    };

    if (foundIndex < 0) {
      list.push(value);
    } else {
      list[foundIndex] = value;
    }

    this.setState({
      validateList: list
    });
  }

  getConfigAttr = (attributes) => attributes.filter(({ node }) => (node.multi === true))

  getSelectedAttrs = (form, attributes) => {
    const configAttrsIds = form.getFieldValue('configurableAttributes');

    if (configAttrsIds) {
      const selectedAttrs = configAttrsIds.map(attrId => {
        const result = attributes.find(({ node }) => node.id === attrId.key);

        if (result && result.node.multi === true) {
          return result.node.code;
        }
        return null;
      }).filter(i => i);

      return selectedAttrs;
    }

    return null;
  }

  setToolSpecField = (form, selectedAttrs) => {
    const toolSpecField = form.getFieldValue("attributes");

    if (selectedAttrs) {
      selectedAttrs.forEach(item => {
        const k = Object.keys(item)[0];
        const v = Object.values(item)[0];

        if (Object.prototype.hasOwnProperty.call(toolSpecField, k)) {
          const fieldName = ["attributes", k];
          form.setFieldValue(fieldName, v);
        }
      });
    }
  }

  updateToolSpecAttr = (form, attributes, index, product) => {
    const products = form.getFieldValue(`configurables`);

    const ps = products.map((p, i) => {
      p = get(p, 'productId.label.props.product');
      if (i === index) {
        return product;
      }
      return p;
    }).filter(i => i);

    const selectedAttrs = this.groupByAttr(form, attributes, ps);

    this.setToolSpecField(form, selectedAttrs);
  }

  validateAttribute = (product, attrName) => {
    const attr = this.state.configAttrsOptions.find(({ node }) => node.name === attrName);
    if (attr) {
      const productAttrCodes = Object.keys(product.attributes);
      const attrCode = attr.node.code;

      return productAttrCodes.includes(attrCode);
    }
    return true;
  }

  validateAttributes = (product, selectedAttrs) => {
    if (product != null && selectedAttrs) {
      const msg = [];

      selectedAttrs.forEach((item) => {
        const containAttr = this.validateAttribute(product, item.label);

        if (!containAttr) {
          msg.push(`[${item.label}]`);
        }
      });

      if (msg.length > 0) {
        msg.push("value is missing in this product's Tools Specs.");
        return msg.join(" ");
      }
    }
    return null;
  }

  validateProduct = (product) => {
    if (product === null || product.type === "simple") {
      const selectedAttrs = this.props.form.getFieldValue('configurableAttributes');
      const validateAttrMsg = this.validateAttributes(product, selectedAttrs);

      if (validateAttrMsg) {
        return {
          validateStatus: 'error',
          errorMsg: validateAttrMsg,
        }
      }

      return {
        validateStatus: 'success',
        errorMsg: null,
      }
    }
    return {
      validateStatus: 'error',
      errorMsg: 'Only Simple product is allowed.',
    }
  }

  validate = (index) => {
    const list = this.state.validateList.find(x => x.id === index);
    if (list) {
      return list.validateStatus;
    }
    return null;
  }

  help = (index) => {
    const list = this.state.validateList.find(x => x.id === index);
    if (list) {
      return list.errorMsg;
    }
    return null;
  }

  groupByAttr = (form, attributes, products) => {
    const selectedAttrs = this.getSelectedAttrs(form, attributes);

    if (selectedAttrs) {
      const attrsOptions = selectedAttrs.map(attrCode => {
        const options = [];
        products.forEach(p => {
          if (Object.keys(p).length > 0 && p.attributes[attrCode] && p.type === "simple") {
            if (Array.isArray(p.attributes[attrCode])) {
              const attr = get(p.attributes[attrCode], '[0]', null);
              if (attr) {
                options.push(attr);
              }
            } else {
              options.push(p.attributes[attrCode]);
            }
          }
        });

        return { [attrCode]: options };
      })

      return this.removeDuplicate(attrsOptions);
    }

    return null;
  }

  removeDuplicate = (attrs) => attrs.map(item => {
    const key = Object.keys(item)[0];
    let value = Object.values(item)[0];

    value = uniq(value);
    return { [key]: value };
  })

  initSelectedAttr = (product) => {
    const attrs = product.configurableAttributes;
    if (attrs) {
      return attrs.map(i => ({ key: i.id, label: i.name }));
    }
    return attrs;
  }

  render() {
    const { viewer, product, form } = this.props;
    const attributes = get(viewer, 'attributes.edges', []);

    const defaultValue = {
      productId: productOptions(null),
      position: 1,
    };

    const initialValue = product.configurables ? product.configurables.edges.slice(0).map(({ node: b }) => {
      const { product: p } = b;

      return {
        ...b,
        productId: productOptions(p),
        position: b.position || 1,
      }
    }) : [defaultValue];

    return (
      <div>
        <FormItem
          {...formItemLayout}
          label="Attribute"
          name="configurableAttributes"
          rules={[{ required: true, message: 'required' }]}
          initialValue={this.initSelectedAttr(product)}
          help="* Only attributes with [Multi Value] turned on are available."
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Attribute"
            labelInValue
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onSelect={() => {
              this.updateToolSpecAttr(form, attributes, null, null);
            }}
            onDeselect={(value) => {
              const toolSpecField = attributes.find(({ node }) => node.id === value.key);
              if (toolSpecField) {
                const fieldName = ["attributes", toolSpecField.node.code];
                this.props.form.setFieldValue(fieldName, undefined);
              }
            }}
          >
            {this.state.configAttrsOptions.map((edge) => {
              const e = edge.node;
              return <Option key={e.id} value={e.id}>{e.name}</Option>;
            })}
          </Select>
        </FormItem>

        <Form.List name="configurables" initialValue={initialValue}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }, i) => {
                const b = form.getFieldValue(["configurables", name]);
                const p = b.product;

                return (
                  <div key={key}>
                    <Divider />
                    <FormItem
                      labelCol={{
                        ...formItemLayout.labelCol,
                        md: { span: 5 }
                      }}
                      wrapperCol={{
                        ...formItemLayout.wrapperCol,
                        md: { span: 19 }
                      }}
                      label={
                        <span>
                          {b.id &&
                            <a target="_blank" href={`/product/${fromGlobalId(b.id).id}`} rel="noopener noreferrer" ><LinkOutlined /></a>
                          }
                          Child
                        </span>
                      }
                      name={[name, "productId"]}
                      rules={[{ required: true, message: 'required' }]}
                      style={{ display: 'inline-block', width: '600px' }}
                      validateStatus={this.validate(i)}
                      help={this.help(i)}
                    >
                      <SelectProduct
                        placeholder="Child"
                        labelInValue
                        showSearch
                        filterOption={false}
                        viewer={viewer}
                        onChange={(value, option) => {
                          if (option) {
                            const newProduct = option.children.props.product;
                            this.updateToolSpecAttr(form, attributes, i, newProduct);
                            this.onProductChange(newProduct, i);
                          }
                        }}
                      />
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label="Position"
                      name={[name, "position"]}
                      style={{ display: 'inline-block', width: '150px' }}
                    >
                      <InputNumber min={0} style={{ width: '50px' }} />
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label="SKU"
                      style={{ display: 'inline-block', width: '140px' }}
                    >
                      {get(p, 'sku')}
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label="Status"
                      style={{ display: 'inline-block', width: '140px' }}
                    >
                      {get(p, 'status')}
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label="Sellable"
                      style={{ display: 'inline-block', width: '165px' }}
                    >
                      {get(p, 'sellable', '').toString()}
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label="Visibility"
                      style={{ display: 'inline-block', width: '175px' }}
                    >
                      {VISIBILITIES.find(v => v.id === get(p, 'visibility', ''))?.label || ""}
                    </FormItem>

                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        style={{ cursor: 'pointer' }}
                        disabled={fields.length === 1}
                        onClick={() => {
                          remove(name);
                          this.updateToolSpecAttr(form, attributes, i, null);
                          this.onProductChange(null, i);
                        }}
                      />
                    ) : null}
                  </div>
                )
              })}
              <Button onClick={() => add(defaultValue)}>
                <PlusOutlined />
              </Button>
            </>
          )}
        </Form.List>
      </div>
    )
  }
}
