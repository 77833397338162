/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UrlRewriteReminderRefetchQueryVariables = {|
  ids?: ?$ReadOnlyArray<?string>
|};
export type UrlRewriteReminderRefetchQueryResponse = {|
  +viewer: ?{|
    +savedUrlRewrites: ?any
  |}
|};
export type UrlRewriteReminderRefetchQuery = {|
  variables: UrlRewriteReminderRefetchQueryVariables,
  response: UrlRewriteReminderRefetchQueryResponse,
|};
*/


/*
query UrlRewriteReminderRefetchQuery(
  $ids: [ID]
) {
  viewer {
    savedUrlRewrites(ids: $ids)
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "ids",
      "variableName": "ids"
    }
  ],
  "kind": "ScalarField",
  "name": "savedUrlRewrites",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UrlRewriteReminderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UrlRewriteReminderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c338c5203cecf88122b9333b3311d55e",
    "id": null,
    "metadata": {},
    "name": "UrlRewriteReminderRefetchQuery",
    "operationKind": "query",
    "text": "query UrlRewriteReminderRefetchQuery(\n  $ids: [ID]\n) {\n  viewer {\n    savedUrlRewrites(ids: $ids)\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c7c3da341d452d0a0750dde2d4da7dd';

module.exports = node;
